<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6">
          <img class="logo" src="@/assets/img/img/logo.svg" height="74" width="334" alt="eurokick-logo" />
          <p>{{ aboutCompany.footerContent }}</p>
          <div class="follow-area" v-if="aboutCompany.facebook || aboutCompany.linkedin || aboutCompany.instagram || aboutCompany.twitter">
            <span>Follow Us</span>
            <img src="@/assets/img/icons/icon-down.svg" />
          </div>

          <div class="follow-account">
            <a href="#" v-if="aboutCompany.facebook">
              <img src="@/assets/img/icons/icon-facebook.svg" />
            </a>
            <a href="#" v-if="aboutCompany.linkedin">
              <img src="@/assets/img/icons/icon-linkedin.svg" />
            </a>
            <a href="#" v-if="aboutCompany.twitter">
              <img src="@/assets/img/icons/icon-twitter.svg" />
            </a>
            <a href="#" v-if="aboutCompany.instagram">
              <img src="@/assets/img/icons/icon-instagram.svg" />
            </a>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="row">
            <div class="col-md-6">
              <div class="site-map">
                <span>Site Map</span>
                <ul>
                  <li>
                    <router-link to="">Founders</router-link>
                  </li>
                  <li>
                    <router-link to="">Portfolio</router-link>
                  </li>
                  <li>
                    <router-link to="">Insights</router-link>
                  </li>
                  <li>
                    <router-link to="">Contact</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mailing-area">
                <span>Join our mailing list!</span>
                <div class="mailing-input">
                  <input placeholder="E-mail Address" />
                  <button type="button" class="btn">
                    <img src="@/assets/img/icons/arrow-cross.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright_area">
        <span>2022 © Eurokick Capital</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",

  computed: {
    ...mapGetters(["aboutCompany"]),
  },
};
</script>
