<template>
  <div class="who-are-we">
    <div class="container">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-md-7">
          <h5>{{ pageContent.section3Title }}</h5>
          <p>{{ pageContent.section3Content }}</p>
        </div>
        <div class="col-6">
          <div class="number">
            <span> {{ pageContent.section3LeftValue }}</span> <span>{{ pageContent.section3LeftKey }}</span>
          </div>
        </div>
        <div class="col-6">
          <div class="number">
            <span> {{ pageContent.section3RightValue }}</span> <span>{{ pageContent.section3RightKey }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import previewDataMixin from "@/mixins/previewData.js";

export default {
  name: "WhoAreWe",

  mixins: [previewDataMixin],
};
</script>
