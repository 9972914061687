<template>
  <div class="blog_detail">
    <div class="container">
      <img :src="pageContent.thumbnailImage" width="1440" height="347" />

      <div class="blog_title">
        <h1>{{ pageContent.title }}</h1>
      </div>

      <div class="row mt-5">
        <div class="col-lg-9">
          <div class="editor" v-html="pageContent.content"></div>
          <div class="file_download" v-if="pageContent.fileUrl">
            <span>{{ pageContent.fileName }}</span>
            <button type="button" class="btn btn-link">Download <img src="@/assets/img/icons/arrow-gray-down.svg" /></button>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="right_bar">
            <div class="publish_date">
              <div class="right_bar_title">Publish Date</div>
              <p v-if="pageContent.createdAt">{{ pageContent.createdAt.split("T")[0] }}</p>
              <p v-else>{{ new Date().toString().split("T")[0] }}</p>
              <p>{{ pageContent.estimatedReadingTime }} min read</p>
            </div>

            <div class="writer" v-if="writer">
              <div class="right_bar_title">Writer</div>
              <a :href="writer.externalLink || null" target="_blank">
                <img class="writer_avatar" :src="writer.image" />
                <div>
                  <span> {{ writer.firstName }} {{ writer.lastName }} </span>
                  <img v-if="writer.externalLink" src="@/assets/img/icons/icon-cross-white.svg" />
                </div>
              </a>
            </div>

            <div class="recommended_posts" v-if="recommendedBlogPosts.length > 0">
              <div class="right_bar_title">Recommended Posts</div>
              <a v-for="post in recommendedBlogPosts" :key="post.id">
                <img src="@/assets/img/icons/arrow-right-sm.svg" />
                <span>{{ post.title }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="gallery_slider" v-if="pageContent.images && pageContent.images.length">
        <div>
          <VueSlickCarousel v-bind="settings">
            <div class="slide_item" v-for="(img, index) in images" :key="index">
              <img :src="img" width="590" height="400" />
            </div>
          </VueSlickCarousel>
        </div>
      </div>

      <div class="related_insights" v-if="relatedBlogPosts.length > 0">
        <h6>Related Insights</h6>
        <div>
          <VueSlickCarousel v-bind="settings">
            <div class="slide_item" v-for="post in relatedBlogPosts" :key="post.id">
              <img :src="post.cardImage" width="590" height="590" />
              <div class="slide_text">
                <p>{{ post.title }}</p>
                <a class="slider_item_link" href="#">
                  <span>Read More</span>
                  <img src="@/assets/img/icons/arrow-right.svg" />
                </a>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import previewDataMixin from "@/mixins/previewData.js";
import { mapGetters } from "vuex";

export default {
  name: "BlogDetails",

  components: {
    VueSlickCarousel,
  },

  mixins: [previewDataMixin],

  data() {
    return {
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["blogPosts", "blogPostWriters", "teamMembers"]),
    recommendedBlogPosts() {
      if (!this.pageContent || !this.pageContent.recommendedBlogPosts) {
        return [];
      }

      const recommendedBlogPostIds = this.pageContent.recommendedBlogPosts.split(",").map((id) => parseInt(id));
      return this.blogPosts.filter((post) => recommendedBlogPostIds.includes(post.id));
    },
    relatedBlogPosts() {
      if (!this.pageContent || !this.pageContent.relatedBlogPosts) {
        return [];
      }

      const relatedBlogPostIds = this.pageContent.relatedBlogPosts.split(",").map((id) => parseInt(id));
      return this.blogPosts.filter((post) => relatedBlogPostIds.includes(post.id));
    },
    writer() {
      // 0 => Anonymous, 1 => External, 2 => Team Member
      const writerType = parseInt(this.pageContent.writerType);
      if (!this.pageContent || writerType === 0) {
        return null;
      }

      if (writerType === 1) {
        const writer = this.blogPostWriters.find((x) => x.id === parseInt(this.pageContent.writerId));
        return {
          externalLink: writer.externalLink,
          firstName: writer.firstName,
          lastName: writer.lastName,
          image: writer.image,
        };
      }

      if (writerType === 2) {
        const teamMember = this.teamMembers.find((x) => x.id === parseInt(this.pageContent.teamMemberId));
        return {
          externalLink: teamMember.externalLink,
          firstName: teamMember.firstName,
          lastName: teamMember.lastName,
          image: teamMember.image,
        };
      }

      return {};
    },
    images() {
      if (!this.pageContent || !this.pageContent.images) {
        return [];
      }

      return this.pageContent.images.split(",");
    },
  },
};
</script>
