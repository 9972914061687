<template>
  <div ref="page">
    <Navbar />
    <component v-for="(comp, index) in components" :key="index" :is="comp" v-bind:content="pageContent" />
    <Footer />
  </div>
</template>

<script>
/* eslint-disable */
import "@/assets/scss/preview.scss";
import Header from "@/components/preview/Header.vue";
import ConnectPeople from "@/components/preview/home/ConnectPeople.vue";
import WhoAreWe from "@/components/preview/home/WhoAreWe.vue";
import Elements from "@/components/preview/home/Elements.vue";
import OurAim from "@/components/preview/home/OurAim.vue";
import Portfolio from "@/components/preview/home/Portfolio.vue";
import Insights from "@/components/preview/home/Insights.vue";
import Footer from "@/components/preview/Footer.vue";
import Navbar from "@/components/preview/Navbar.vue";
import previewDataMixin from "@/mixins/previewData.js";
import blogActions from "@/store/blog/actions.js";
import partnerActions from "@/store/partner/actions.js";
import companyActions from "@/store/company/actions.js";
import FoundersCard from "@/components/preview/founders/FoundersCard.vue";
import teamMemberActions from "@/store/teamMember/actions.js";
import FounderDetails from "@/components/preview/founders/FounderDetails.vue";
import PartnersCard from "@/components/preview/partners/PartnersCard.vue";
import PartnerDetails from "@/components/preview/partners/PartnerDetails.vue";
import BlogCards from "@/components/preview/blogs/BlogCards.vue";
import BlogDetails from "@/components/preview/blogs/BlogDetails.vue";
import ContactForm from "@/components/preview/contact/ContactForm.vue";
import previewActions from "@/store/preview/actions.js";

let pageLoader = null;

export default {
  name: "Preview",

  components: {
    Footer,
    Navbar,
  },

  mixins: [previewDataMixin],

  data() {
    return {
      components: [],
    };
  },

  async mounted() {
    pageLoader = this.$loading.show({ container: this.$refs.page });
    this.components = await this.getComponentsByPageName(this.pageContent.page);

    pageLoader.hide();
  },

  methods: {
    async getComponentsByPageName(name) {
      await this.$store.dispatch(companyActions.GET_ABOUT_COMPANY_CONTENT);

      switch (name) {
        case "home":
          await this.$store.dispatch(blogActions.GET_HOME_PAGE_BLOG_POSTS);
          await this.$store.dispatch(partnerActions.GET_HOME_PAGE_PARTNERS);
          this.$store.dispatch(previewActions.PREVIEW_HEADER_CONTENT, {
            backgroundImage: "home-bg-1.png",
            showButton: true,
          });

          return [Header, ConnectPeople, WhoAreWe, Elements, OurAim, Portfolio, Insights];

        case "founders":
          await this.$store.dispatch(teamMemberActions.GET_ALL_TEAM_MEMBERS);
          this.$store.dispatch(previewActions.PREVIEW_HEADER_CONTENT, {
            backgroundImage: "bg-founders.png",
            showButton: false,
          });

          return [Header, FoundersCard];

        case "founderDetails":
          await this.$store.dispatch(partnerActions.GET_HOME_PAGE_PARTNERS);
          await this.$store.dispatch(blogActions.GET_ALL_BLOG_POSTS);

          return [FounderDetails];

        case "partners":
          await this.$store.dispatch(partnerActions.GET_ALL_PARTNERS);
          this.$store.dispatch(previewActions.PREVIEW_HEADER_CONTENT, {
            backgroundImage: "bg-portfolio.png",
            showButton: false,
          });

          return [Header, PartnersCard];

        case "partnerDetails":
          return [PartnerDetails];

        case "blogs":
          await this.$store.dispatch(blogActions.GET_ALL_BLOG_POSTS);
          this.$store.dispatch(previewActions.PREVIEW_HEADER_CONTENT, {
            backgroundImage: "bg-insights.png",
            showButton: false,
          });

          return [Header, BlogCards];

        case "blogDetails":
          await this.$store.dispatch(blogActions.GET_ALL_BLOG_POSTS);
          await this.$store.dispatch(blogActions.GET_ALL_BLOG_POST_WRITERS);
          await this.$store.dispatch(teamMemberActions.GET_ALL_TEAM_MEMBERS);

          return [BlogDetails];

        case "contact":
          await this.$store.dispatch(companyActions.GET_ABOUT_COMPANY_CONTENT);
          this.$store.dispatch(previewActions.PREVIEW_HEADER_CONTENT, {
            backgroundImage: "bg-contact.png",
            showButton: false,
          });

          return [Header, ContactForm];

        default:
          return [];
      }
    },
  },
};
</script>
