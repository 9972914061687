<template>
  <div class="container" v-if="blogPosts && blogPosts.length">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6" v-for="post in blogPosts" :key="post.id">
        <div class="blog_card">
          <div class="img-wrapper">
            <img :src="post.cardImage" class="img-fluid" />
          </div>
          <div class="blog_info">
            <div class="blog_category">{{ post.category.name }}</div>
            <p class="blog_short_text">{{ post.title }}</p>
            <a class="slider_item_link" href="#">
              <span>Read More</span>
              <img src="@/assets/img/icons/arrow-right-md.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import previewDataMixin from "@/mixins/previewData.js";
import { mapGetters } from "vuex";

export default {
  name: "BlogCards",

  mixins: [previewDataMixin],

  computed: {
    ...mapGetters(["blogPosts"]),
  },
};
</script>
