<template>
  <div class="header" :style="{ backgroundImage: 'url(' + require('@/assets/img/bg/' + previewHeaderContent.backgroundImage) + ')' }">
    <div class="container">
      <h1>{{ pageContent.section1Title }}</h1>
      <router-link v-if="previewHeaderContent.showButton" to="">Our Portfolio</router-link>
      <h2 v-else>{{ pageContent.section1Content }}</h2>
    </div>
  </div>
</template>

<script>
import previewData from "@/mixins/previewData.js";
import { mapGetters } from "vuex";

export default {
  name: "Header",

  mixins: [previewData],

  computed: {
    ...mapGetters(["previewHeaderContent"]),
    backgroundImage() {
      return "";
    },
  },
};
</script>
