<template>
  <div class="portfolio" v-if="homePagePartners && homePagePartners.length">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <h5>{{ pageContent.section6Title }}</h5>
          <p class="portfolio_desc">{{ pageContent.section6Content }}</p>
        </div>

        <div class="col-lg-5 col-md-6" v-for="partner in homePagePartners" :key="partner.id">
          <div class="company">
            <img class="company_logo" :src="partner.image" alt="{partner.logoAlt}" />
            <p class="compony_desc">{{ partner.description }}</p>
            <a class="company_detail_link" :href="partner.externalLink" target="_blank">
              <span>See Website</span>
              <img src="@/assets/img/icons/arrow-right.svg" />
            </a>
          </div>
        </div>

        <div class="col-lg-2">
          <div class="all_company_link">
            <router-link to="/">
              <span>See Our Portfolio</span>
              <img src="@/assets/img/icons/arrow-right-md.svg" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import previewDataMixin from "@/mixins/previewData.js";
import { mapGetters } from "vuex";

export default {
  name: "Portfolio",

  mixins: [previewDataMixin],

  computed: {
    ...mapGetters(["homePagePartners"]),
  },
};
</script>
