<template>
  <div class="connectPeople">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="left-area">
            <div class="d-flex align-items-center justify-content-between w-100">
              <h5>{{ pageContent.section2Left }}</h5>
              <div class="people-wrapper">
                <img src="@/assets/img/img/people.png" />
                <img src="@/assets/img/img/people.png" />
                <img src="@/assets/img/img/people.png" />
              </div>
            </div>
            <a href="/" class="d-flex align-items-center justify-content-between w-100">
              <span>Check our team</span>
              <img src="@/assets/img/icons/arrow-right.svg" />
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="right-area">
            <p>{{ pageContent.section2Right }}</p>
            <a href="/" class="d-flex align-items-center justify-content-between w-100">
              <span>View our Portfolio</span>
              <img src="@/assets/img/icons/arrow-right.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/home/connectPeople.module.scss";
import previewDataMixin from "@/mixins/previewData.js";

export default {
  name: "ConnectPeople",

  mixins: [previewDataMixin],
};
</script>
