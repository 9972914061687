<template>
  <div class="elements">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12">
          <h5>{{ pageContent.section4Title }}</h5>
          <p class="elements_desc">{{ pageContent.section4Content }}</p>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="elements-item">
            <h6>{{ pageContent.section4FirstTitle }}</h6>
            <p>{{ pageContent.section4FirstContent }}</p>
            <img :src="pageContent.section4FirstImage" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="elements-item">
            <h6>{{ pageContent.section4SecondTitle }}</h6>
            <p>{{ pageContent.section4SecondContent }}</p>
            <img :src="pageContent.section4SecondImage" />
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="elements-item">
            <h6>{{ pageContent.section4ThirdTitle }}</h6>
            <p>
              {{ pageContent.section4ThirdContent }}
            </p>
            <img :src="pageContent.section4ThirdImage" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import previewDataMixin from "@/mixins/previewData.js";

export default {
  name: "Elements",

  mixins: [previewDataMixin],
};
</script>
