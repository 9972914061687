<template>
  <div class="founder_detail">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img :src="pageContent.image" width="700" height="500" />
        </div>
        <div class="col-md-6">
          <h1>{{ pageContent.firstName }} {{ pageContent.lastName }}</h1>
          <div class="founder_social_media">
            <a href="#" v-if="pageContent.linkedin">
              <img src="@/assets/img/icons/icon-gray-linkedin.svg" />
            </a>
            <a href="#" v-if="pageContent.twitter">
              <img src="@/assets/img/icons/icon-gray-twitter.svg" />
            </a>
            <a href="#" v-if="pageContent.instagram">
              <img src="@/assets/img/icons/icon-gray-instagram.svg" />
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-6">
              <div class="founder_info">
                <p>Office</p>
                <span>{{ pageContent.office }}</span>
              </div>
              <div class="founder_info" v-if="pageContent.categories">
                <p>Categories</p>
                <span>{{ pageContent.categories }}</span>
              </div>
            </div>
            <div class="col-6" v-if="pageContent.partners">
              <div class="founder_info">
                <p>Partners</p>
                <ul>
                  <li v-for="(partner, index) in pageContent.partners.split(',')" :key="index">
                    <img src="@/assets/img/icons/arrow-right-sm.svg" />
                    <span>{{ partner }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="founder_about">
            <h6>About</h6>
            <p class="founder_about_content" v-html="pageContent.about"></p>
          </div>
        </div>
        <div class="col-12" v-if="teamMemberBlogPosts && teamMemberBlogPosts.length">
          <h6>Insights from {{ pageContent.firstName }}</h6>
          <VueSlickCarousel v-bind="settings">
            <div class="slide_item" v-for="post in teamMemberBlogPosts" :key="post.id">
              <img :src="post.cardImage" width="590" height="590" />
              <div class="slide_text">
                <p>{{ post.title }}</p>
                <a class="slider_item_link" href="#">
                  <span>Read More</span>
                  <img src="@/assets/img/icons/arrow-right.svg" />
                </a>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import previewDataMixin from "@/mixins/previewData.js";
import { mapGetters } from "vuex";

export default {
  name: "FounderDetails",

  components: {
    VueSlickCarousel,
  },

  mixins: [previewDataMixin],

  data() {
    return {
      settings: {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["blogPosts"]),
    teamMemberBlogPosts() {
      if (!this.pageContent.blogPosts) {
        return [];
      }

      const blogPostIds = this.pageContent.blogPosts.split(",").map((id) => parseInt(id));
      return this.blogPosts.filter((blogPost) => blogPostIds.includes(blogPost.id));
    },
  },
};
</script>

<style scoped>
.col-md-6 {
  margin-bottom: 64px;
}
.founder_detail {
  min-height: "100vh";
}
.founder_detail .container {
  padding-top: 200px;
}
.founder_social_media a img {
  padding-right: 28px;
}
.founder_info {
  margin-bottom: 32px;
}
.founder_info p {
  font-weight: 400;
  font-size: 28px;
  line-height: 35px;
}
.founder_info span {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
}
.founder_info li {
  margin-bottom: 16px;
}
.founder_info li img {
  margin-right: 8px;
}
.founder_about {
  margin-bottom: 64px;
}
.founder_about_content {
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
}
.slide_item {
  padding: 20px;
}
.slider_item_link {
  display: flex;
}
.slider_item_link img {
  padding-left: 32px;
}
.slide_text {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
}
.slide_text p {
  margin-bottom: 40px;
  margin-top: 24px;
}
.slide_text a span {
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
}
.founder_about_content h2 {
  font-size: 50px;
  line-height: 62px;
  margin-bottom: 16px;
}
@media (max-width: 1441px) {
  .founder_about_content h2 {
    font-size: 45px;
    line-height: 80px;
  }
}
@media (max-width: 1199px) {
  .founder_about_content h2 {
    font-size: 40px;
    line-height: 75px;
  }
}
@media (max-width: 991px) {
  .founder_about_content h2 {
    font-size: 35px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .founder_about_content h2 {
    font-size: 30px;
    line-height: 50px;
  }
}
@media (max-width: 575px) {
  .founder_about_content h2 {
    font-size: 25px;
    line-height: 40px;
  }
}
@media (max-width: 424px) {
  .founder_about_content h2 {
    font-size: 20px;
    line-height: 35px;
  }
}
.founder_about_content ul {
  padding-left: 40px;
}
.founder_about_content ul li {
  font-weight: 400 !important;
  font-size: 21px !important;
  line-height: 34px !important;
  list-style: disc;
}
.founder_about_content ul li a {
  color: #28a5e5;
}
.founder_about_content ol {
  padding-left: 40px;
}
.founder_about_content ol li {
  font-weight: 400 !important;
  font-size: 21px !important;
  line-height: 34px !important;
  list-style: decimal;
}
.founder_about_content ol li a {
  color: #28a5e5;
}
.founder_about_content h6 {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .founder_about ul li,
  .founder_about ol li {
    font-size: 16px;
    line-height: 28px;
  }
}
</style>
