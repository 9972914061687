export default {
  data() {
    return {
      pageContent: {},
    };
  },

  mounted() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.pageContent = params;
  },
};
