<template>
  <div class="container" v-if="partners && partners.length">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 mb-5 position-relative text-center" v-for="partner in partners" :key="partner.id">
        <div class="companyCard">
          <router-link to="">
            <a>
              <img :src="partner.logo" width="120" height="50" />
              <div class="companyCard_text">
                <img src="@/assets/img/icons/arrow-right-md.svg" />
                <p class="company_type">E-Commerce</p>
              </div>
            </a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import previewDataMixin from "@/mixins/previewData.js";
import { mapGetters } from "vuex";

export default {
  name: "PartnersCard",

  mixins: [previewDataMixin],

  computed: {
    ...mapGetters(["partners"]),
  },
};
</script>

<style scoped>
.companyCard {
  padding: 10px 40px !important;
  transition: all 0.3s ease-in-out;
}
.companyCard .companyCard_text {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.companyCard:hover {
  background-color: #1b2029;
}
.companyCard:hover .companyCard_text {
  opacity: 1;
  visibility: visible;
}
</style>
