<template>
  <div class="foundersCard" v-if="teamMembers && teamMembers.length">
    <div class="container">
      <div class="row">
        <div class="col-md-6" v-for="member in teamMembers" :key="member.id">
          <div class="card">
            <router-link to="">
              <a>
                <div class="card_img">
                  <img :src="member.image" width="700" height="500" />
                </div>
                <div class="founder_info">
                  <div class="d-flex align-items-center">
                    <h6 class="founder_name">{{ member.firstName }} {{ member.lastName }}</h6>
                    <img src="@/assets/img/icons/arrow-right.svg" width="100" height="18" />
                  </div>
                  <span class="founder_company">{{ member.partners.length ? member.parnerrs[0].name : "" }}</span>
                </div>
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FoundersCard",

  computed: {
    ...mapGetters(["teamMembers"]),
  },
};
</script>
