<template>
  <div class="navbar_wrapper">
    <div class="container">
      <div class="navbar">
        <div>
          <router-link to="">
            <a> <img src="@/assets/img/img/logo.svg" width="230" height="44" /></a>
          </router-link>
        </div>
        <div class="topnav">
          <router-link to="">
            <a class="navbar_item">Founders</a>
          </router-link>
          <router-link to="">
            <a class="navbar_item">Portfolio</a>
          </router-link>
          <router-link to="">
            <a class="navbar_item">Insights</a>
          </router-link>
          <router-link to="">
            <a class="navbar_item">Contact</a>
          </router-link>
          <a href="#" class="icon"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
