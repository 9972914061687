<template>
  <div class="company_detail">
    <div class="container">
      <div class="company_title">
        <h1>{{ pageContent.name }}</h1>
        <div class="company_link">
          <router-link to="" v-if="pageContent.externalLink">
            <a>
              <img src="@/assets/img/icons/arrow-cross-gray.svg" width="22" height="22" />
            </a>
          </router-link>
          <router-link to="" v-if="pageContent.linkedin">
            <a>
              <img src="@/assets/img/icons/icon-gray-linkedin.svg" width="22" height="22" />
            </a>
          </router-link>
          <router-link to="" v-if="pageContent.twitter">
            <a>
              <img src="@/assets/img/icons/icon-gray-twitter.svg" width="22" height="22" />
            </a>
          </router-link>
          <router-link to="" v-if="pageContent.instagram">
            <a>
              <img src="@/assets/img/icons/icon-gray-instagram.svg" width="22" height="22" />
            </a>
          </router-link>
        </div>
      </div>
      <img :src="pageContent.image" width="1440" height="347" />
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6" v-if="partnerTeamMembers && partnerTeamMembers.length">
          <div class="company_info">
            <p>Team</p>
            <ul>
              <li v-for="(member, index) in partnerTeamMembers" :key="index">{{ member }}</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" v-if="milestones && milestones.length">
          <div class="company_info">
            <p>Milestones</p>
            <ul>
              <li v-for="(milestone, index) in milestones" :key="index">{{ milestone }}</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" v-if="partners && partners.length">
          <div class="company_info">
            <p>Partner</p>
            <ul>
              <li v-for="(partner, index) in partners" :key="index">
                <img src="@/assets/img/icons/arrow-right-sm.svg" />
                <span>{{ partner }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="more_details">
        <span>More Details</span>
        <img src="@/assets/img/icons/icon-down.svg" />
      </div>
      <div class="company_description">
        <div v-html="pageContent.content"></div>
        <div v-if="pageContent.externalLink || pageContent.linkedin || pageContent.twitter || pageContent.instagram">
          <h6>Channels</h6>
          <div class="company_social_media">
            <router-link to="" v-if="pageContent.linkedin">
              <a>
                <img src="@/assets/img/icons/icon-linkedin.svg" width="24" height="24" />
              </a>
            </router-link>
            <router-link to="" v-if="pageContent.twitter">
              <a>
                <img src="@/assets/img/icons/icon-twitter.svg" width="24" height="24" />
              </a>
            </router-link>
            <router-link to="" v-if="pageContent.instagram">
              <a>
                <img src="@/assets/img/icons/icon-instagram.svg" width="24" height="24" />
              </a>
            </router-link>
            <router-link to="" v-if="pageContent.externalLink">
              <a>
                <span>{{ pageContent.externalLink }}</span>
                <img src="@/assets/img/icons/icon-cross-white.svg" width="12" height="12" />
              </a>
            </router-link>
          </div>
        </div>
      </div>
      <div class="suggested_post">
        <div>
          <VueSlickCarousel v-bind="settings">
            <div class="slide_item">
              <img src="@/assets/img/img/slide1.png" width="590" height="590" />
            </div>
            <div class="slide_item">
              <img src="@/assets/img/img/slide2.png" width="590" height="590" />
            </div>
            <div class="slide_item">
              <img src="@/assets/img/img/slide2.png" width="590" height="590" />
            </div>
            <div class="slide_item">
              <img src="@/assets/img/img/slide2.png" width="590" height="590" />
            </div>
            <div class="slide_item">
              <img src="@/assets/img/img/slide2.png" width="590" height="590" />
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import previewDataMixin from "@/mixins/previewData.js";

export default {
  name: "PartnerDetails",

  components: {
    VueSlickCarousel,
  },

  mixins: [previewDataMixin],

  data() {
    return {
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
            },
          },
        ],
      },
    };
  },

  computed: {
    partnerTeamMembers() {
      if (!this.pageContent || !this.pageContent.partnerTeamMembers) {
        return [];
      }

      return this.pageContent.partnerTeamMembers.split(",");
    },
    milestones() {
      if (!this.pageContent || !this.pageContent.milestones) {
        return [];
      }

      return this.pageContent.milestones.split(",");
    },
    partners() {
      if (!this.pageContent || !this.pageContent.partners) {
        return [];
      }

      return this.pageContent.partners.split(",");
    },
  },
};
</script>
