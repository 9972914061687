<template>
  <div class="our_aim">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <h5>{{ pageContent.section5Title }}</h5>
          <p class="our_aim_desc">{{ pageContent.section5Content }}</p>
        </div>
        <div class="col-md-6">
          <div class="our_aim_item">
            <img :src="pageContent.section5FirstImage" />
            <h5>{{ pageContent.section5FirstTitle }}</h5>
            <p>{{ pageContent.section5FirstContent }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="our_aim_item">
            <img :src="pageContent.section5SecondImage" />
            <h5>{{ pageContent.section5SecondTitle }}</h5>
            <p>{{ pageContent.section5SecondContent }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import previewDataMixin from "@/mixins/previewData.js";

export default {
  name: "OurAim",

  mixins: [previewDataMixin],
};
</script>
