<template>
  <div class="insights" v-if="homePageBlogPosts && homePageBlogPosts.length">
    <div class="container">
      <div>
        <h5>Insights</h5>
        <p>Insights and perspectives from the Eurokick</p>
      </div>
      <VueSlickCarousel v-bind="settings">
        <div class="slide_item" v-for="post in homePageBlogPosts" :key="post.id">
          <img :src="post.cardImage" width="590" height="590" />
          <div class="slide_text">
            <p>{{ post.title }}</p>
            <router-link to="/">
              <a>
                <span>Read More</span>
                <img src="@/assets/img/icons/arrow-right.svg" />
              </a>
            </router-link>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapGetters } from "vuex";

export default {
  name: "Insights",

  components: {
    VueSlickCarousel,
  },

  data() {
    return {
      settings: {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: true,
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters(["homePageBlogPosts"]),
  },
};
</script>
