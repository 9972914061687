<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-8">
        <div class="contact_form">
          <h6>Contact Form</h6>
          <span>I’m interested in ;</span>

          <div class="interested_buttons">
            <button type="button" class="btn">Investment</button>
            <button type="button" class="btn">Partnership</button>
            <button type="button" class="btn">Info</button>
            <button type="button" class="btn">Be Partner</button>
            <button type="button" class="btn">Carrier</button>
          </div>
          <div class="form">
            <form>
              <div class="row">
                <div class="col-md-6 mb-5">
                  <label htmlFor="name" class="form-label"> Name </label>
                  <input type="text" class="form-control" id="name" placeholder="Type your name" />
                </div>
                <div class="col-md-6 mb-5">
                  <label htmlFor="surname" class="form-label"> Surname </label>
                  <input type="text" class="form-control" id="surname" placeholder="Type your surname" />
                </div>
                <div class="col-md-6 mb-5">
                  <label htmlFor="phone" class="form-label"> Phone </label>
                  <input type="text" class="form-control" id="phone" placeholder="(___)___ __ __" />
                </div>
                <div class="col-md-6 mb-5">
                  <label htmlFor="email" class="form-label"> E-Mail </label>
                  <input type="email" class="form-control" id="email" placeholder="Type your email" />
                </div>
                <div class="col-12 mb-5">
                  <label htmlFor="textarea" class="form-label"> Message </label>
                  <textarea class="form-control" placeholder="Leave a comment here" id="textarea"></textarea>
                </div>
                <div class="col-md-6">
                  <button type="submit" class="btn btn-primary">
                    Send
                    <img src="@/assets/img/icons/arrow-right-md.svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="contact_info">
            <div class="contact_address" v-if="aboutCompany.address">
              <h6>Address</h6>
              <span>{{ aboutCompany.address }}</span>
            </div>
            <div class="contact_phone" v-if="aboutCompany.phone">
              <h6>Phone</h6>
              <span>{{ aboutCompany.phone }}</span>
            </div>
            <div class="contact_phone" v-if="aboutCompany.email">
              <h6>Mail</h6>
              <span>{{ aboutCompany.email }}</span>
            </div>
            <div class="contact_phone" v-if="aboutCompany.linkedin || aboutCompany.twitter || aboutCompany.instagram">
              <h6>Channels</h6>
              <a v-if="aboutCompany.linkedin">
                <img src="@/assets/img/icons/icon-linkedin.svg" />
              </a>
              <a v-if="aboutCompany.twitter">
                <img src="@/assets/img/icons/icon-twitter.svg" />
              </a>
              <a v-if="aboutCompany.instagram">
                <img src="@/assets/img/icons/icon-instagram.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-4 col-sm-none d-none d-sm-block">
        <img src="@/assets/img/img/contact-img.png" class="img-fluid w-100" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ContactForm",

  computed: {
    ...mapGetters(["aboutCompany"]),
  },
};
</script>
